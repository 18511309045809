<template>
  <div>
    <p>
      Atomic notation uses superscripts and subscripts to denote important information about a given
      atom or ion. Answer the following questions about atomic notation:
    </p>
    <p>
      a) Identify the meaning of each component in the standard atomic notation of an element:
      <span><chemical-latex content="^A_Z X^e" /></span>
    </p>
    <v-container>
      <v-row v-for="question in questions" :key="question">
        <v-col cols="12" align-self="center">
          <v-select v-model="inputs[question]" :items="dropdownComponent" :label="question + ':'" />
        </v-col>
      </v-row>
    </v-container>
    <p>
      b) How many protons, neutrons, and electrons are present based on the atomic notation
      <chemical-latex :content="AtomicNotation" />
    </p>
    <v-text-field v-model="inputs.protons" label="Protons:" hint="Enter answer here" />
    <v-text-field v-model="inputs.neutrons" label="Neutrons:" hint="Enter answer here" />
    <v-text-field v-model="inputs.electrons" label="Electrons:" hint="Enter answer here" />
    <v-text-field v-model="inputs.charge" label="Charge:" hint="Enter answer here" />
  </div>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'QuestionComponentsAtomicNotation',
  components: {
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      questions: ['A', 'Z', 'X', 'e'],
      inputs: {
        A: null,
        Z: null,
        X: null,
        e: null,
        protons: null,
        neutrons: null,
        electrons: null,
        charge: null,
      },
      dropdownComponent: [
        {text: 'Atomic Number', value: 'atomicNumber'},
        {text: 'Chemical Symbol', value: 'chemicalSymbol'},
        {text: 'Electrical Charge', value: 'electricalCharge'},
        {text: 'Electrons', value: 'electrons'},
        {text: 'Mass Number', value: 'massNumber'},
        {text: 'Neutrons', value: 'neutrons'},
      ],
    };
  },
  computed: {
    Am() {
      return this.taskState.getValueBySymbol('aM').content;
    },
    Cc() {
      return this.taskState.getValueBySymbol('cC').content;
    },
    AtomicNotation() {
      return '^{' + this.Am.value + '}_{23} V^{' + this.Cc.value + '+}\\,?';
    },
  },
};
</script>

<style scoped></style>
